import ErrorAlert from "./ErrorAlert";
import { useStore } from "@nanostores/react";
import { $alertOptions } from "@/stores/alert-options";
import React, { useEffect, useRef, useState } from "react";
import { useTranslations } from "@/i18n/utils";

type AlertProps = {
  lang: "ar" | "en";
};

const Alert: React.FC<AlertProps> = ({ lang }) => {
  const t = useTranslations(lang);
  const ref = useRef<HTMLDialogElement>(null);
  const alertOptions = useStore($alertOptions);
  const [loading, setLoading] = useState(false);
  const [err, setError] = useState<Error | null>(null);

  useEffect(() => {
    const dialog = ref.current;

    if (!dialog) {
      return;
    }

    if (alertOptions.show) {
      dialog.showModal();
    } else {
      dialog.close();
    }
  }, [alertOptions.show]);

  return (
    <dialog ref={ref} className="modal modal-bottom sm:modal-middle">
      <div className={"modal-box"}>
        {((alertOptions.title && alertOptions.title.length > 0) ||
          (alertOptions.description &&
            alertOptions.description.length > 0)) && (
          <React.Fragment>
            {err && <ErrorAlert message={err.message} />}
            <h3 className="font-bold text-lg">{alertOptions.title}</h3>
            {alertOptions.description && (
              <div
                className="py-4"
                dangerouslySetInnerHTML={{ __html: alertOptions.description }}
              />
            )}
            <div className="modal-action">
              <form
                method="dialog"
                className="flex ml-auto items-center gap-2 rtl:flex-row-reverse"
                onSubmit={() => $alertOptions.set({ show: false })}
              >
                {alertOptions.cancelButton ? (
                  <button
                    type="button"
                    className="btn mr-2"
                    onClick={() => {
                      alertOptions.cancelButton!.onClick();
                      $alertOptions.set({ show: false });
                    }}
                  >
                    {t("admin.title.cancel")}
                  </button>
                ) : (
                  <button type="submit" className="btn mr-2">
                    {t("admin.title.cancel")}
                  </button>
                )}
                {alertOptions.actionButton && (
                  <button
                    type="button"
                    onClick={async () => {
                      setLoading(true);

                      try {
                        alertOptions.actionButton!.onClick();
                      } catch (err) {
                        setError(err as Error);
                      } finally {
                        setLoading(false);
                        // $alertOptions.set({ show: false });
                      }
                    }}
                    className={
                      alertOptions.actionButton.type === "info"
                        ? "btn btn-info"
                        : alertOptions.actionButton.type === "error"
                          ? "btn btn-error"
                          : alertOptions.actionButton.type === "warning"
                            ? "btn btn-warning"
                            : alertOptions.actionButton.type === "success"
                              ? "btn btn-success"
                              : "btn"
                    }
                  >
                    {loading && (
                      <span className="loading loading-spinner mr-2"></span>
                    )}
                    {alertOptions.actionButton.title}
                  </button>
                )}
              </form>
            </div>
          </React.Fragment>
        )}
      </div>
    </dialog>
  );
};

export default Alert;
